<template>
  <div class="notice">
    <div class="container-right">
      <div class="buttonOut">
        <span>单位名称：</span>
        <el-input v-model="name" clearable size="small" style="width: 200px" placeholder="请输入单位名称" />
        <span style="margin-left: 20px"></span>
        <el-button size="small" @click="currentChangeHandle(1)">查询</el-button>
      </div>
      <div style="width: 100%; padding: 8px">
        <el-table :data="tableData">
          <el-table-column type="index" width="50" label="序号" align="center" />
          <el-table-column prop="nodeName" label="单位名称" width="300" align="center" />
          <el-table-column prop="noticeUrl" label="通知路径" align="center" />
          <el-table-column label="操作" width="200" align="center">
            <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="updatHandle(scope.row)">修改</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="pageNo"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          :total="totalCount"
          layout="total, sizes, prev, pager, next, jumper"
        >
        </el-pagination>
      </div>
    </div>
    <notice-update ref="notice-update" :info="rowInfo" @refreshList="getNoticeList" />
  </div>
</template>

<script>
import noticeUpdate from "@/views/modules/notice-update";
export default {
  name: "notice",
  components: { noticeUpdate },
  data() {
    return {
      rowInfo: {},
      curNode: {},
      tableData: [],
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      name: "",
    };
  },
  mounted() {
    this.getNoticeList();
  },
  methods: {
    updatHandle(val) {
      this.rowInfo = val;
      this.$refs["notice-update"].init();
    },
    getNoticeList() {
      const Loading = this.$mask();
      this.$http({
        url: this.$http.adornUrl("/obd/notice/list"),
        method: "get",
        params: {
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          name: this.name,
          carPlateColor: this.carPlateColor,
        },
      })
        .then((res) => {
          Loading.close();
          this.tableData = res.data.page.list || [];
          this.totalCount = res.data.page.totalCount || 0;
        })
        .catch((err) => {
          Loading.close();
          this.$message.error(err.msg);
        });
    },
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageNo = 1;
      this.getNoticeList();
    },
    currentChangeHandle(val) {
      this.pageNo = val;
      this.getNoticeList();
    },
  },
};
</script>